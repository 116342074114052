import styled from "styled-components";

export const Button = styled.button`
  background: #222222;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: #ffffff;
  min-height: 60px;
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  border: 2px solid #222222;
  gap: 10px;
  &:hover {
    border: 2px solid #1f5454;
    background: #4d9a9a;
  }
`;
