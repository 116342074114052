import styled from "styled-components";
import LoginBg from "../../assets/images/landing-bg.png";

export const Container = styled.div`
  height: calc(100vh - 0px);
  width: 100%;
  background-size: cover;
  background-image: url(${LoginBg});
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  width: 350px;
`;

export const Header = styled.div`
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #fff;
`;

export const Content = styled.div`
  padding: 15px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #fff;
`;
export const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #003c3c;
  margin-bottom: 55px;
  margin-top: 40px;
}
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
