import { Button } from "../../components/button";
import * as Styles from "./styles";
import { ReactComponent as HeaderLogo } from "../../assets/icons/header-logo.svg";

export interface LandingPageProps {
  UserRole?: string[];
  userName?: string;
}

export default function LandingPage({ UserRole, userName }: LandingPageProps) {
  const onHandleButtonClick = (buttonName: string) => {
    if (buttonName === "admin") {
      window.location.replace(process.env.REACT_APP_ADMIN_URL || "");
    } else if (buttonName === "supervisor") {
      window.location.replace(process.env.REACT_APP_SUPERVISOR_URL || "");
    } else if (buttonName === "operator") {
      window.location.replace(
        `${process.env.REACT_APP_OPERATOR_URL}task-id` || ""
      );
    } else {
      window.location.replace(process.env.REACT_APP_TESTER_URL || "");
    }
  };

  return (
    <Styles.Container>
      <Styles.Card>
        <Styles.Header>
          {/* <HeaderLogo /> */}
          <Styles.Welcome>Welcome !</Styles.Welcome>
          <Styles.Name>{userName}</Styles.Name>
        </Styles.Header>
        <Styles.Content>
          <Styles.Title>Access Application As</Styles.Title>
          <Styles.ButtonContainer>
            {UserRole?.map((role, index) => {
              return (
                <div key={index}>
                  {role === "carteplus.supervisor" ? (
                    <Button
                      className="supervisor-btn"
                      buttonText="Supervisor"
                      onClick={() => onHandleButtonClick("supervisor")}
                    />
                  ) : role === "carteplus.admin" ? (
                    <Button
                      className="admin-btn"
                      buttonText="Admin"
                      onClick={() => onHandleButtonClick("admin")}
                    />
                  ) : role === "carteplus.picker" ? (
                    <Button
                      className="picker-btn"
                      buttonText="Picker"
                      onClick={() => onHandleButtonClick("picker")}
                    />
                  ) : role === "carteplus.operator" ? (
                    <Button
                      className="operator-btn"
                      buttonText="Operator"
                      onClick={() => onHandleButtonClick("operator")}
                    />
                  ) : null}
                </div>
              );
            })}
          </Styles.ButtonContainer>
        </Styles.Content>
      </Styles.Card>
    </Styles.Container>
  );
}
