import * as Styles from "./styles";

export interface ButtonProps {
  className: string;
  buttonText: string;
  onClick?: () => void;
  icon?: JSX.Element;
}

export function Button({ className, buttonText, onClick, icon }: ButtonProps) {
  return (
    <Styles.Button className={className} onClick={onClick}>
      {icon}
      {buttonText}
    </Styles.Button>
  );
}
