import { Button } from "../../components/button";
import * as Styles from "./styles";
import { ReactComponent as HeaderLogo } from "../../assets/icons/header-logo.svg";
import { ReactComponent as MicroIcon } from "../../assets/icons/micro.svg";
export interface LoginProps {
  handleLogin: (loginType: string) => void;
}

export default function Login({ handleLogin }: LoginProps) {
  return (
    <Styles.Container>
      <Styles.Card>
        <Styles.Header>
          <HeaderLogo />
        </Styles.Header>
        <Styles.Content>
          <Styles.Title>User Login</Styles.Title>
          <Styles.ButtonContainer>
            <Button
              icon={<MicroIcon />}
              className="login-btn"
              buttonText="Sign in with Microsoft"
              onClick={() => handleLogin("popup")}
            />
          </Styles.ButtonContainer>
        </Styles.Content>
      </Styles.Card>
    </Styles.Container>
  );
}
