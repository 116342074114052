import LandingPage from "../pages/landing-page/LandingPage";
import { loginRequest } from "./auth/AuthConfig";
import { useAccount, useIsAuthenticated } from "@azure/msal-react";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Login from "../pages/login/Login";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const UserRole = account?.idTokenClaims?.roles;
  const userName = account?.idTokenClaims?.name;

  const handleLogin = (loginType: any) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <>
      {isAuthenticated ? (
        <LandingPage UserRole={UserRole} userName={userName} />
      ) : (
        <Login handleLogin={handleLogin} />
        // <button onClick={() => handleLogin("popup")}>
        //   Sign in using Popup
        // </button>
      )}
      {/* <LandingPage /> */}
    </>
  );
}

export default App;
