import styled from "styled-components";
import LoginBg from "../../assets/images/landing-bg.png";

export const Container = styled.div`
  height: calc(100vh - 0px);
  width: 100%;
  background-size: cover;
  background-image: url(${LoginBg});
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  width: 450px;
`;

export const Header = styled.div`
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: center;
  padding: 15px 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #fff;
  color: #003c3c;
  font-weight: 700;
  gap: 0px;
`;

export const Content = styled.div`
  padding: 15px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #fff;
`;
export const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  color: #003c3c;
  margin-bottom: 25px;
  margin-top: 5px;
`;

export const Welcome = styled.div`
  font-size: 40px;
`;

export const Name = styled.div`
  font-size: 30px;
  word-wrap: break-word;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
